import { Stack, Text, Button, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import useVideoContext from '../../hooks/useVideoContext';
import AvatarIcon from '../../icons/AvatarIcon';
import VideoTrack from '../VideoTrack';

export default function LocalVideoPreview() {
  const { localTracks, getAudioAndVideoTracks } = useVideoContext();
  const toast = useToast();

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  useEffect(() => {
    getAudioAndVideoTracks().catch(e => {
      let title = 'Allow access to camera and microphone';
      let description = 'Blazetime needs to have access to your camera and microphone.';
      if (e.message === 'CameraPermissionsDenied') {
        title = 'Allow access to camera';
        description = 'Blazetime needs to have access to your camera.';
      }
      if (e.message === 'MicrophonePermissionsDenied') {
        title = 'Allow access to microphone';
        description = 'Blazetime needs to have access to your microphone.';
      }
      toast({
        title,
        description,
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
    });
  }, []);

  function requestPermissions() {
    getAudioAndVideoTracks().catch(e => {
      toast({
        title: 'Allow access to camera and microphone',
        description: 'Blazetime needs to have access to your camera and microphone.',
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
    });
  }

  return (
    <Stack m={0} p={0} w="100%" h="100%" direction="column" alignItems="center" justifyContent="center">
      {videoTrack && localTracks.length === 2 && <VideoTrack track={videoTrack} isLocal />}
      {localTracks.length < 2 && (
        <>
          <AvatarIcon />
          <Text textAlign="center" color="#FFFEE9" fontSize={{ lg: '24px' }} fontWeight={400}>
            Blazetime needs access to your camera and microphone.
          </Text>
          <Button _active={{}} _hover={{}} onClick={requestPermissions}>
            Give access
          </Button>
        </>
      )}
    </Stack>
  );
}

import { extendTheme } from '@chakra-ui/react';

const colors = {
  light: {
    100: '#FFFEE9',
  },
  black: {
    100: '#13252B',
  },
};
const fonts = {
  body: 'Lato, sans-serif',
  heading: 'Lato, sans-serif',
};

export default extendTheme({ colors, fonts });

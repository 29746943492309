import { Stack, Button } from '@chakra-ui/react';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle';
import useVideoContext from '../../hooks/useVideoContext';
import { VIDEOCHAT_STATES } from '../VideoChatRoom';

type VideoChatRoomButtonsProps = {
  onMainButtonClick: () => void;
  mainButtonText: string;
  onStopButtonClick: () => void;
  state: VIDEOCHAT_STATES;
};

export default function VideoChatRoomButtons({
  mainButtonText,
  onMainButtonClick,
  onStopButtonClick,
  state,
}: VideoChatRoomButtonsProps) {
  const { isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const [isVideoEnabled] = useLocalVideoToggle();
  const disableButtons = isAcquiringLocalTracks || isConnecting || !isVideoEnabled;
  return (
    <Stack
      h="100%"
      w={{ base: '100%', lg: 'unset' }}
      justifyContent={{ base: 'space-between', lg: 'center' }}
      direction={{ base: 'row', lg: 'column' }}
      pl={{ lg: '16px' }}
    >
      <Button
        _active={{}}
        _hover={{}}
        _disabled={{
          opacity: 0.5,
        }}
        w={{ base: '130px', lg: 'initial' }}
        h={{ base: '86px', lg: 'initial' }}
        px={{ base: '4px', lg: '20px' }}
        py={{ base: '10px', lg: '40px' }}
        borderStyle="solid"
        borderWidth={{ base: '1px', lg: '2px' }}
        fontSize="24px"
        fontWeight={700}
        fontFamily="Nunito"
        borderColor="rgba(17, 239, 33, 0.60);"
        bg="linear-gradient(93deg, rgba(17, 239, 33, 0.30) 1.88%, rgba(239, 232, 0, 0.30) 101.06%)"
        onClick={onMainButtonClick}
        isDisabled={disableButtons || [VIDEOCHAT_STATES.WAITING_FOR_PARTNER].includes(state)}
      >
        {mainButtonText}
      </Button>
      <Button
        _active={{}}
        _hover={{}}
        _disabled={{
          opacity: 0.5,
        }}
        w={{ base: '130px', lg: 'initial' }}
        h={{ base: '86px', lg: 'initial' }}
        fontFamily="Nunito"
        fontSize="24px"
        fontWeight={700}
        px={{ base: '4px', lg: '20px' }}
        py={{ base: '10px', lg: '40px' }}
        borderStyle="solid"
        borderWidth={{ base: '1px', lg: '2px' }}
        borderColor="rgba(239, 57, 17, 0.60);"
        bg="linear-gradient(247deg, rgba(239, 57, 17, 0.30) 14.88%, rgba(239, 232, 0, 0.30) 123.74%)"
        onClick={onStopButtonClick}
        isDisabled={disableButtons || [VIDEOCHAT_STATES.STOP].includes(state)}
      >
        Stop
      </Button>
    </Stack>
  );
}

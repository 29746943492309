import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  Text,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
//import ReCAPTCHA from 'react-google-recaptcha';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PasswordEyeIcon from '../../icons/PasswordEyeIcon';

export type SignInUpFormProps = {
  onCreateAccount: (values: FormFields) => void;
  onSignIn: (values: FormFields) => void;
  onKeepMeSignedInChange: (value: boolean) => void;
  onForgotPassword: () => void;
  onForgotPasswordClaimed: (email: string) => void;
  isSignUp?: boolean;
  showForgotPassword: boolean;
};

export type FormFields = {
  email: string;
  password: string;
};

type ForgotPasswordFields = Omit<FormFields, 'password'>;

export default function SignInUpForm({
  onForgotPassword,
  onForgotPasswordClaimed,
  onKeepMeSignedInChange,
  onCreateAccount,
  onSignIn,
  showForgotPassword = false,
  isSignUp = true,
}: SignInUpFormProps) {
  //const captchaRef = useRef<any>(null);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm<FormFields>();

  const { email, password } = watch();

  const {
    register: registerFP,
    handleSubmit: handleSubmitFP,
    formState: { errors: errorsFP },
    setValue: setValueFP,
    watch: watchFP,
    clearErrors: clearErrorsFP,
  } = useForm<ForgotPasswordFields>();

  const { email: emailFP } = watchFP();

  useEffect(() => {
    setValue('email', '');
    setValue('password', '');
    clearErrors('email');
    clearErrors('password');
  }, [isSignUp, setValue, clearErrors]);

  useEffect(() => {
    setValueFP('email', '');
    clearErrorsFP('email');
  }, [showForgotPassword, setValueFP, clearErrorsFP]);

  function onSubmit(values: FormFields) {
    /*const token = captchaRef.current.getValue();
    if (!token) {
    }
    captchaRef.current.reset();*/
    isSignUp ? onCreateAccount(values) : onSignIn(values);
  }

  function onSubmitFP() {
    onForgotPasswordClaimed(emailFP);
  }

  if (showForgotPassword) {
    return (
      <form onSubmit={handleSubmitFP(onSubmitFP)}>
        <Stack direction="column" spacing={{ base: '8px', lg: '16px' }} color="light.100">
          <FormControl isInvalid={!!errorsFP.email?.message}>
            <Input
              fontFamily="Nunito"
              fontSize={{ base: '12px', lg: '16px' }}
              fontWeight={700}
              color="rgba(255, 254, 233, 0.50)"
              _placeholder={{
                color: 'rgba(255, 254, 233, 0.50)',
                py: { lg: '4px' },
              }}
              borderRadius="12px"
              _focus={{ borderColor: '#11EF2199' }}
              type="text"
              placeholder="Email address"
              {...registerFP('email', {
                required: 'Please enter your email address.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter a valid email address.',
                },
              })}
            />
            {errorsFP.email?.message && <FormErrorMessage>{errorsFP.email?.message}</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Button
          _active={{}}
          _hover={{}}
          _disabled={{ opacity: 0.3 }}
          type="submit"
          fontWeight={700}
          w="100%"
          fontSize={{ base: '14px', lg: '20px' }}
          mt={{ base: '15px', lg: '30px' }}
          boxShadow="0px 4px 12px 0px rgba(17, 239, 33, 0.25)"
          bg="linear-gradient(92deg, rgba(17, 239, 33, 0.30) 1.66%, rgba(239, 232, 0, 0.30) 98.34%), #FFFEE9"
          isDisabled={!!errorsFP.email?.message || !emailFP}
          borderColor="rgba(239, 232, 0, 0.60)"
          borderRadius="12px"
          px={{ lg: '20px' }}
          py={{ lg: '16px' }}
        >
          Reset password
        </Button>
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={{ base: '8px', lg: '16px' }} color="light.100">
        <FormControl isInvalid={!!errors.email?.message}>
          <Input
            fontFamily="Nunito"
            fontSize={{ base: '12px', lg: '16px' }}
            fontWeight={700}
            color="rgba(255, 254, 233, 0.50)"
            _placeholder={{
              color: 'rgba(255, 254, 233, 0.50)',
              py: { lg: '4px' },
            }}
            borderRadius="12px"
            _focus={{ borderColor: '#11EF2199' }}
            type="text"
            placeholder="Email address"
            {...register('email', {
              required: 'Please enter your email address.',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Please enter a valid email address.',
              },
            })}
          />
          {errors.email?.message && <FormErrorMessage>{errors.email?.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.password?.message}>
          <InputGroup>
            <Input
              color="rgba(255, 254, 233, 0.50)"
              _placeholder={{
                color: 'rgba(255, 254, 233, 0.50)',
                py: { lg: '4px' },
              }}
              borderRadius="12px"
              fontFamily="Nunito"
              fontSize={{ base: '12px', lg: '16px' }}
              fontWeight={700}
              _focus={{ borderColor: '#11EF2199' }}
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              {...register('password', {
                minLength: {
                  value: 6,
                  message: 'Password must be at least 6 chars length.',
                },
                maxLength: {
                  value: 12,
                  message: 'Password must be lower than 12 chars length.',
                },
                required: 'Please enter your password.',
              })}
            />
            <InputRightElement>
              <Button
                _active={{}}
                _hover={{}}
                border=""
                variant="outline"
                p={2}
                m={2}
                onClick={() => setShowPassword(!showPassword)}
              >
                <PasswordEyeIcon />
              </Button>
            </InputRightElement>
          </InputGroup>
          {errors.password?.message && <FormErrorMessage>{errors.password?.message}</FormErrorMessage>}
        </FormControl>
      </Stack>
      <Stack spacing={{ base: '16px', lg: 0 }} direction="row" justifyContent="space-between">
        {!isSignUp && (
          <Checkbox
            defaultChecked={JSON.parse(window.localStorage.getItem('keepSignedIn') || 'false')}
            onChange={e => onKeepMeSignedInChange(e.target.checked)}
            mt={{ lg: '18px' }}
            w="100%"
            color="light.100"
            fontWeight={700}
            fontSize={{ base: '12px', lg: '16px' }}
          >
            Keep me signed
          </Checkbox>
        )}
        {!isSignUp && (
          <Text
            w="100%"
            textAlign="right"
            mt={{ lg: '18px' }}
            color="light.100"
            onClick={onForgotPassword}
            fontSize={{ base: '12px', lg: '16px' }}
          >
            Forgot password?
          </Text>
        )}
      </Stack>
      {/*<ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY as string} />*/}
      <Button
        _active={{}}
        _hover={{}}
        _disabled={{ opacity: 0.3 }}
        type="submit"
        fontWeight={700}
        w="100%"
        fontSize={{ base: '14px', lg: '20px' }}
        mt={{ base: '15px', lg: '30px' }}
        boxShadow="0px 4px 12px 0px rgba(17, 239, 33, 0.25)"
        bg="linear-gradient(92deg, rgba(17, 239, 33, 0.30) 1.66%, rgba(239, 232, 0, 0.30) 98.34%), #FFFEE9"
        isDisabled={!!(errors.email?.message && errors.password?.message) || !(email && password)}
        borderColor="rgba(239, 232, 0, 0.60)"
        borderRadius="12px"
        px={{ lg: '20px' }}
        py={{ lg: '16px' }}
      >
        {isSignUp ? 'Create Account' : 'Sign in'}
      </Button>
    </form>
  );
}

import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LetsBlaze from './pages/LetsBlaze';
import { LayoutProvider } from './providers/LayoutProvider';

export default createBrowserRouter([
  {
    path: '/',
    element: (
      <LayoutProvider>
        <HomePage />
      </LayoutProvider>
    ),
  },
  {
    path: '/blaze',
    element: (
      <LayoutProvider>
        <LetsBlaze />
      </LayoutProvider>
    ),
  },
]);

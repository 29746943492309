import { BoxProps, Box } from '@chakra-ui/react';

export default function PasswordEyeIcon(props: BoxProps) {
  return (
    <Box {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30" viewBox="0 0 36 30" fill="none">
        <path
          d="M11.8461 17.0996C11.6065 16.4395 11.48 15.7437 11.4721 15.0416C11.4745 13.369 12.14 11.7656 13.3227 10.583C14.5053 9.40029 16.1087 8.7348 17.7812 8.73237C18.5058 8.73237 19.1909 8.88068 19.8393 9.10643L22.8357 6.10999C21.1163 5.39074 19.393 4.91562 17.7826 4.91562C7.87712 4.91693 0.0625 15.0416 0.0625 15.0416C0.0625 15.0416 2.90669 18.7034 7.32062 21.6264L11.8461 17.0982V17.0996ZM27.6486 8.72187L23.6376 12.7329C23.9224 13.4508 24.0904 14.2291 24.0904 15.0429C24.088 16.7155 23.4226 18.319 22.2399 19.5019C21.0573 20.6848 19.4539 21.3506 17.7812 21.3534C16.9895 21.3503 16.2056 21.1966 15.4712 20.9006L12.2294 24.1424C13.9724 24.7724 15.8335 25.1701 17.7812 25.1701C25.3544 25.1701 35.5 15.0442 35.5 15.0442C35.5 15.0442 32.035 11.5976 27.6486 8.72449V8.72187ZM30.3051 0.495117L3.15344 27.6468L5.01062 29.5053L32.161 2.3523L30.3051 0.495117Z"
          fill="#132B27"
          fillOpacity="0.5"
        />
      </svg>
    </Box>
  );
}

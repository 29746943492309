import React from 'react';

export default function HomePageVideo() {
  return (
    <video style={{ width: '100%', height: '100%', objectFit: 'fill' }} muted={true} autoPlay={true} loop={true}>
      <source src="/assets/blazetime.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}

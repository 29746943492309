import React from 'react';
import Video from 'twilio-video';

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  if (!Video.isSupported) {
    return <h1>Video tag is not supported</h1>;
  }

  return children;
}

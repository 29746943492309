import React from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

export default function SignInButton() {
  const navigate = useNavigate();

  return (
    <Button
      _active={{}}
      _hover={{}}
      onClick={() => navigate('/')}
      type="submit"
      fontSize={{ base: '14px', lg: '20px' }}
      boxShadow="0px 4px 12px 0px rgba(17, 239, 33, 0.25)"
      bg="linear-gradient(92deg, rgba(17, 239, 33, 0.30) 1.66%, rgba(239, 232, 0, 0.30) 98.34%), #FFFEE9"
      borderColor="rgba(239, 232, 0, 0.60)"
      borderRadius={{ base: '8px', lg: '12px' }}
      px={{ base: '10px', lg: '20px' }}
      py={{ base: '5px', lg: '16px' }}
    >
      Sign in
    </Button>
  );
}

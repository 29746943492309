import { useEffect, useState } from 'react';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';
import useVideoContext from './useVideoContext';

export default function useRemoteParticipant() {
  const { room } = useVideoContext();
  const [participant, setParticipant] = useState<RemoteParticipant | undefined>(getRemoteParticipant());

  function getRemoteParticipant() {
    const participants = Array.from(room?.participants.values() ?? []);
    if (room?.localParticipant) {
      // @ts-ignore
      const remoteParticipant = participants.find(participant => participant.sid !== room.localParticipant.sid);
      return remoteParticipant;
    }
    return undefined;
  }

  useEffect(() => {
    if (room) {
      setParticipant(getRemoteParticipant());

      const participantConnected = (participant: RemoteParticipant) => {
        // @ts-ignore
        if (participant.sid !== room.localParticipant.sid) {
          setParticipant(participant);
        }
      };
      const participantDisconnected = (participant: RemoteParticipant) => {
        // @ts-ignore
        if (participant.sid !== room.localParticipant.sid) {
          setParticipant(undefined);
        }
      };
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  return participant;
}

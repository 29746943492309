import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import theme from './theme';
import AppStateProvider from './providers/AppStateProvider';
import UnsupportedBrowserWarning from './providers/UnsupportedBrowserWarningProvider';
import { ParticipantProvider } from './providers/ParticipantProvider';
import { VideoProvider } from './providers/VideoProvider';
import { AuthContextProvider } from './providers/AuthProvider';
import { AnalyticsContextProvider } from './providers/AnalyticsProvider';

// demo comment
function App() {
  return (
    <ChakraProvider theme={theme}>
      <UnsupportedBrowserWarning>
        <AuthContextProvider>
          <AnalyticsContextProvider>
            <AppStateProvider>
              <VideoProvider>
                <ParticipantProvider>
                  <RouterProvider router={router} />
                </ParticipantProvider>
              </VideoProvider>
            </AppStateProvider>
          </AnalyticsContextProvider>
        </AuthContextProvider>
      </UnsupportedBrowserWarning>
    </ChakraProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

import { IconButton, Stack, Text } from '@chakra-ui/react';
import useParticipantsContext from '../../hooks/useParticipantsContext';
import Participant from '../Participant';
import ParticipantAudioTracks from '../ParticipantAudioTracks';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import Emojis, { EmojiNames } from '../../icons/EmojiIcons';
import EmojiButton from '../../icons/EmojiButtonIcon';
import usePublications from '../../hooks/usePublications';

type MobileRemoteParticipantProps = {
  reactWithEmoji: (emojiName: EmojiNames) => void;
};

export default function MobileRemoteParticipant({ reactWithEmoji = () => {} }: MobileRemoteParticipantProps) {
  const { remoteParticipant = null } = useParticipantsContext();
  const publications = usePublications(remoteParticipant);

  const filteredPublications = publications.filter(p => !p.trackName.includes('screen'));

  function _reactWithEmoji(emoji: EmojiNames) {
    reactWithEmoji(emoji);
  }

  return (
    <Stack
      borderWidth="2px"
      borderStyle="solid"
      borderColor="rgba(255, 254, 233, 0.50)"
      w="100%"
      h="100%"
      direction="row"
      position="relative"
      justifyContent="center"
      alignItems="center"
      borderRadius={{ base: '16px', lg: '32px' }}
    >
      {filteredPublications.length === 0 && (
        <Text textAlign="center" color="#FFFEE9" fontSize={{ base: '14px', lg: '24px' }} fontWeight={400}>
          BlazeTime is sparking up
        </Text>
      )}
      {remoteParticipant && filteredPublications.length > 0 && (
        <>
          <ParticipantAudioTracks />
          <Participant key={remoteParticipant.sid} videoOnly participant={remoteParticipant} />
          <Stack position="absolute" top={0} right={0} pb={{ base: '10px', lg: '15px' }}>
            <Menu>
              <MenuButton
                icon={<EmojiButton />}
                _selected={{}}
                _active={{}}
                _hover={{}}
                variant="outline"
                w="64px"
                h="52px"
                borderRadius="16px"
                px={{ lg: '24px' }}
                py={{ lg: '8px' }}
                borderWidth={{ lg: '2px' }}
                borderStyle="solid"
                borderColor="rgba(17, 239, 33, 0.60);"
                bg="linear-gradient(93deg, rgba(17, 239, 33, 0.30) 1.88%, rgba(239, 232, 0, 0.30) 101.06%)"
                as={IconButton}
                aria-label="Emojis"
              />
              <MenuList>
                <MenuItem
                  icon={<Emojis iconName={EmojiNames.WEED} />}
                  onClick={() => _reactWithEmoji(EmojiNames.WEED)}
                />
                <MenuItem
                  icon={<Emojis iconName={EmojiNames.JOINT} />}
                  onClick={() => _reactWithEmoji(EmojiNames.JOINT)}
                />
              </MenuList>
            </Menu>
          </Stack>
        </>
      )}
    </Stack>
  );
}

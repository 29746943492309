import { Box, BoxProps } from '@chakra-ui/react';

interface MicProps extends BoxProps {
  isMuted?: boolean;
}

const UnmutedIcon = `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_113_1853)">
      <path
        d="M18 24C21.9 24 25 20.9 25 17V9C25 5.1 21.9 2 18 2C14.1 2 11 5.1 11 9V17C11 20.9 14.1 24 18 24Z"
        fill="#FFFEE9"
      />
      <path
        d="M30 17H28C28 22.5 23.5 27 18 27C12.5 27 8 22.5 8 17H6C6 23.3 10.8 28.4 17 28.9V32H14C13.4 32 13 32.4 13 33C13 33.6 13.4 34 14 34H22C22.6 34 23 33.6 23 33C23 32.4 22.6 32 22 32H19V28.9C25.2 28.4 30 23.3 30 17Z"
        fill="#FFFEE9"
      />
    </g>
    <defs>
      <clipPath id="clip0_113_1853">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>`;

const MutedIcon = `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_113_1888)">
      <path
        d="M30.0001 17H28.0001C28.0001 18.8 27.5001 20.5 26.6001 22L28.1001 23.5C29.3001 21.5 29.9001 19.3 30.0001 17Z"
        fill="#FFFEE9"
      />
      <path
        d="M24.9999 17V9.00001C24.9999 5.10001 21.7999 2.00001 17.8999 2.10001C14.9999 2.10001 12.2999 4.00001 11.3999 6.80001L24.3999 19.8C24.7999 18.9 24.9999 17.9 24.9999 17Z"
        fill="#FFFEE9"
      />
      <path
        d="M25.2001 26.6001L32.1001 33.5001L33.5001 32.1001L4.0001 2.6001L2.6001 4.0001L11.0001 12.4001V17.0001C11.0001 20.9001 14.1001 24.0001 18.0001 24.0001C19.3001 24.0001 20.5001 23.7001 21.6001 23.0001L23.8001 25.2001C22.1001 26.4001 20.1001 27.0001 18.0001 27.0001C12.6001 27.2001 8.2001 22.9001 8.0001 17.6001V17.0001H6.0001C6.1001 23.2001 10.8001 28.4001 17.0001 29.0001V32.0001H14.0001C13.4001 32.0001 13.0001 32.4001 13.0001 33.0001C13.0001 33.6001 13.4001 34.0001 14.0001 34.0001H22.0001C22.6001 34.0001 23.0001 33.6001 23.0001 33.0001C23.0001 32.4001 22.6001 32.0001 22.0001 32.0001H19.0001V29.0001C21.2001 28.8001 23.4001 28.0001 25.2001 26.6001Z"
        fill="#FFFEE9"
      />
    </g>
    <defs>
      <clipPath id="clip0_113_1888">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>`;

export default function MicIcon({ isMuted = false, ...props }: MicProps) {
  return <Box {...props} dangerouslySetInnerHTML={{ __html: isMuted ? MutedIcon : UnmutedIcon }} />;
}

import { Stack, IconButton, Link } from '@chakra-ui/react';
import UserMenu from '../UserMenu';
import OnlinePeople from '../OnlinePeople';
import { useLocation } from 'react-router';
import BlazetimeIcon from '../../icons/BlazetimeIcon';

export default function Navbar() {
  const { pathname } = useLocation();

  return (
    <Stack
      borderBottomLeftRadius="24px"
      borderBottomRightRadius="24px"
      bg="light.100"
      borderColor="black.100"
      direction="row"
      w="100vw"
      px={{ base: '32px', lg: '60px' }}
      pt={{ base: '32px', lg: '32px' }}
      pb={{ base: '32px', lg: '32px' }}
      justifyContent="space-between"
      alignItems="center"
    >
      <IconButton
        onClick={() => window.location.assign('https://blazetime.ca')}
        _hover={{}}
        bg="none"
        icon={<BlazetimeIcon />}
        aria-label="Blazetime"
        w={{ base: '132px', lg: '212px' }}
        h={{ base: '30px', lg: '50px' }}
      />
      <OnlinePeople />
      <Stack alignItems="center" direction="row">
        <Link
          target="_blank"
          href="mailto:blazetimeca@gmail.com"
          border="none"
          _hover={{}}
          fontFamily="Nunito"
          color="#1D1D1B"
          fontWeight={400}
          fontSize={{ base: '14px', lg: '20px' }}
        >
          Help
        </Link>
        {pathname === '/' ? null : <UserMenu />}
      </Stack>
    </Stack>
  );
}

import React, { createContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useAuthContext from '../hooks/useAuthContext';

mixpanel.init('dfecbb4730ef19ed0c6f328fc4ea5c9f');

export interface IAnalyticsProviderContext {
  registerMixpanelEvent: (event: string, params?: any) => void;
  registerGAEvent: (event: string, params?: any) => void;
}

export const AnalyticsContext = createContext<IAnalyticsProviderContext>({
  registerGAEvent: () => {},
  registerMixpanelEvent: () => {},
});

export const AnalyticsContextProvider: React.FC = ({ children }) => {
  const { app } = useAuthContext();
  const analytics = getAnalytics(app);

  function registerMixpanelEvent(event: string, params: any = {}) {
    mixpanel.track(event, params);
  }

  function registerGAEvent(event: string, params: any = {}) {
    logEvent(analytics, event, params);
  }

  return (
    <AnalyticsContext.Provider
      value={{
        registerGAEvent,
        registerMixpanelEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

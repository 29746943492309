import React, { createContext } from 'react';
import { Stack } from '@chakra-ui/react';
import Navbar from '../components/Navbar';

export const LayoutProviderContext = createContext(null);

export const LayoutProvider: React.FC = ({ children }) => {
  return (
    <LayoutProviderContext.Provider value={null}>
      <Stack bg="black.100" h="100vh" w="100vw" spacing={0} id="app-container" p={0} m={0} direction="column">
        <Navbar />
        {children}
      </Stack>
    </LayoutProviderContext.Provider>
  );
};

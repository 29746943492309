import { Checkbox, Link } from '@chakra-ui/react';

type AcceptanceCheckboxProps = {
  onChange: (value: boolean) => void;
};

export default function AcceptanceCheckbox({ onChange }: AcceptanceCheckboxProps) {
  return (
    <Checkbox
      borderColor="#B85D50"
      size="md"
      color="#B85D50"
      fontFamily="Lato"
      fontWeight={700}
      fontSize={{ base: '16px', lg: '20px' }}
      onChange={e => onChange(e.target.checked)}
    >
      I am 18+ years old and acknowledge the{' '}
      <Link textDecoration="underline" color="white" href="https://www.blazetime.ca/terms-and-conditions" isExternal>
        Terms & Conditions
      </Link>
    </Checkbox>
  );
}

import { Stack, Button, Text, useToast } from '@chakra-ui/react';
import useVideoContext from '../../hooks/useVideoContext';
import Emojis, { EmojiNames } from '../../icons/EmojiIcons';
import { animated, useSpring } from '@react-spring/web';
import MobileUserVideo from '../UserVideo/mobile';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useAppState } from '../../hooks/useAppState';
import useAuthContext from '../../hooks/useAuthContext';
import useAnalyitcsContext from '../../hooks/useAnalyticsContext';
import AttachVisibilityHandler from '../AttackVisibilityHandler';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle';
import useFlipCameraToggle from '../../hooks/useFlipCameraToggle';
import MobileLocalVideoPreview from '../LocalVideoPreview/mobile';
import Mic from '../../icons/MicIcon';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle';
import FlipCamera from '../../icons/FlipCamera';
import MobileRemoteParticipant from '../RemoteParticipant/mobile';

const socketUrl = process.env.NODE_ENV === 'production' ? '/' : (process.env.REACT_APP_SOCKET_SERVER_URL as string);
let socket: any = null;

export enum VIDEOCHAT_STATES {
  WAITING_FOR_PARTNER,
  PARTNER_FOUND,
  CONNECT_TO_YOUR_ROOM,
  DISCONNECT_FROM_ROOM,
  CONNECTED,
  STOP,
}

export type APP_STATE = {
  data?: any;
  state: VIDEOCHAT_STATES;
};

export default function MobileVideoChatRoom() {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
  const {
    roomState,
    room,
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
    localTracks,
  } = useVideoContext();
  const toast = useToast();
  const [isVideoEnabled] = useLocalVideoToggle();
  const disableButtons = isAcquiringLocalTracks || isConnecting || !isVideoEnabled;
  const [roomId, setRoomId] = useState('');
  const [error, setError] = useState({ title: '', description: '' });
  const { registerGAEvent, registerMixpanelEvent } = useAnalyitcsContext();
  const { currentUser } = useAuthContext();
  const { updateOnlineUsers } = useAppState();
  const [emoji, setEmoji] = useState<EmojiNames | null>(null);
  const flyOutAnimation = useSpring({
    from: {
      left: '60%',
      top: '60%',
      transform: 'scale(1)',
    },
    to: {
      left: '20%',
      top: '35%',
      transform: 'scale(2)',
    },
    config: { duration: 800 },
    reset: true,
    onRest: () => {
      setEmoji(null);
    },
  });
  const [status, setStatus] = useState<APP_STATE>({ state: VIDEOCHAT_STATES.CONNECTED });

  useEffect(() => {
    const event = 'blaze-status';
    registerGAEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
    registerMixpanelEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
    switch (status.state) {
      case VIDEOCHAT_STATES.PARTNER_FOUND:
      case VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM:
        connectTo(status.data.token);
        break;
      case VIDEOCHAT_STATES.DISCONNECT_FROM_ROOM:
        if (status.data.roomId === roomId) {
          room?.disconnect();
          setRoomId('');
          if (status.data.fromStop) {
            return setStatus({ state: VIDEOCHAT_STATES.CONNECTED });
          }
          return setStatus({ state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER });
        }
        setStatus({ state: status.data.oldState as VIDEOCHAT_STATES });
        break;
    }
  }, [status]);

  useEffect(() => {
    if (error.title) {
      const event = 'blazes-server-error';
      registerGAEvent(event, {
        user: currentUser ? currentUser.uid : 'anonymous-user',
        status: status.state,
        socketId: socket.id,
      });
      registerMixpanelEvent(event, {
        user: currentUser ? currentUser.uid : 'anonymous-user',
        status: status.state,
        socketId: socket.id,
      });
      toast({
        title: error.title,
        description: error.description,
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    socket = io(socketUrl);

    socket.on('server:onlineUsers', (onlineUsers: number) => updateOnlineUsers(onlineUsers));

    socket.on('server:error', ({ title, description }: any) => {
      setError({ title, description });
    });

    socket.on('server:partnerFound', (partnerFoundResponse: any) => {
      if (!partnerFoundResponse) {
        return setStatus({
          data: null,
          state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER,
        });
      }
      setRoomId(partnerFoundResponse.roomId);
      setStatus({
        data: partnerFoundResponse,
        state: VIDEOCHAT_STATES.PARTNER_FOUND,
      });
    });

    socket.on('server:partnerFoundDueSkip', (partnerFoundResponse: any) => {
      if (!partnerFoundResponse) {
        return setStatus({
          data: null,
          state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER,
        });
      }
      if (partnerFoundResponse.id === socket.id) {
        room?.disconnect();
        setRoomId(partnerFoundResponse.roomId);
        setStatus({
          data: partnerFoundResponse,
          state: VIDEOCHAT_STATES.PARTNER_FOUND,
        });
      }
    });

    socket.on('server:connectToYourRoom', ({ to, token, roomId }: any) => {
      if (socket.id === to) {
        setRoomId(roomId);
        setStatus({ data: { token }, state: VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM });
      }
    });

    socket.on('server:emoji', ({ to, emoji }: any) => {
      if (socket.id === to) {
        setEmoji(emoji as EmojiNames);
      }
    });

    socket.on('server:disconnectFromRoom', ({ roomId, fromStop = false }: any) => {
      setStatus({ data: { roomId, fromStop, oldState: status.state }, state: VIDEOCHAT_STATES.DISCONNECT_FROM_ROOM });
    });

    return () => {
      room?.disconnect();
      socket.disconnect();
    };
  }, []);

  function reactWithEmoji(emoji: EmojiNames) {
    registerGAEvent(`blaze-emoji-${emoji.toString()}-choose`, {
      user: currentUser ? currentUser.uid : 'anonymous-user',
      status: status.state,
    });
    registerMixpanelEvent(`blaze-emoji-${emoji.toString()}-choose`, {
      user: currentUser ? currentUser.uid : 'anonymous-user',
      status: status.state,
    });
    socket.emit('client:emoji', emoji);
    setEmoji(emoji);
  }

  function onLetSkipBlaze() {
    if (status.state === VIDEOCHAT_STATES.PARTNER_FOUND || status.state === VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM) {
      registerGAEvent('blaze-skip', { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
      registerMixpanelEvent('blaze-skip', {
        user: currentUser ? currentUser.uid : 'anonymous-user',
        status: status.state,
      });
      setStatus({ state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER });
      return socket.emit('client:skip');
    }
    registerGAEvent('blaze-start', { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
    registerMixpanelEvent('blaze-start', {
      user: currentUser ? currentUser.uid : 'anonymous-user',
      status: status.state,
    });
    setStatus({ state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER });
    socket.emit('client:findPartner');
  }

  function onStop() {
    registerGAEvent('blaze-stop', { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
    registerMixpanelEvent('blaze-stop', {
      user: currentUser ? currentUser.uid : 'anonymous-user',
      status: status.state,
    });
    setStatus({ state: VIDEOCHAT_STATES.STOP });
    socket.emit('client:stop');
  }

  async function connectTo(token: string) {
    try {
      await videoConnect(token);
      const event = 'videochat-started';
      registerGAEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user' });
      registerMixpanelEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user' });
    } catch (e) {
      const event = 'videochat-started-failed';
      registerGAEvent(event, {
        // @ts-ignore
        error: e.message,
      });
      registerMixpanelEvent(event, {
        // @ts-ignore
        error: e.message,
      });
      toast({
        title: 'There was an error',
        // @ts-ignore
        description: `Can't connect with your Blazetime partner.`,
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
      setStatus({ state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER });
    }
  }

  function getMainButtonText() {
    switch (status.state) {
      case VIDEOCHAT_STATES.CONNECTED:
      case VIDEOCHAT_STATES.STOP:
        return 'Blaze!';
      case VIDEOCHAT_STATES.WAITING_FOR_PARTNER:
      case VIDEOCHAT_STATES.DISCONNECT_FROM_ROOM:
        return 'Waiting...';
      case VIDEOCHAT_STATES.PARTNER_FOUND:
      case VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM:
        return 'Skip';
    }
  }

  function getRemoteParticipantText() {
    switch (status.state) {
      case VIDEOCHAT_STATES.CONNECTED:
      case VIDEOCHAT_STATES.STOP:
        return `Cannabis comes in various strains, each with its own unique combination of cannabinoids and terpenes.
          Indica strains are often associated with relaxation, while sativa strains may be more energizing.`;
      case VIDEOCHAT_STATES.WAITING_FOR_PARTNER:
      case VIDEOCHAT_STATES.DISCONNECT_FROM_ROOM:
        return 'Waiting for a smoke buddy';
      case VIDEOCHAT_STATES.PARTNER_FOUND:
      case VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM:
        return 'Connecting to your smoke buddy...';
    }
  }

  return (
    <Stack
      w="100%"
      py="20px"
      px="20px"
      spacing="16px"
      h="100%"
      justifyContent="space-between"
      alignItems="center"
      direction="column"
    >
      <AttachVisibilityHandler />
      {emoji && (
        <animated.div style={{ zIndex: 9999, position: 'absolute', ...flyOutAnimation }}>
          <Emojis iconName={emoji} />
        </animated.div>
      )}

      <Stack
        w="100%"
        flexGrow={2}
        position="relative"
        bg="rgba(255, 254, 233, 0.50)"
        borderRadius={{ base: '16px', lg: '32px' }}
        p={0}
        alignItems="center"
        justifyContent="center"
      >
        <Text
          p="12px"
          w="100%"
          hidden={roomState === 'connected'}
          textAlign="center"
          color="#FFFEE9"
          fontSize="24px"
          fontWeight={400}
        >
          {getRemoteParticipantText()}
        </Text>
        {roomState !== 'disconnected' && <MobileRemoteParticipant reactWithEmoji={reactWithEmoji} />}
        <Stack
          w="100%"
          bottom="4px"
          position="absolute"
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Stack hidden={localTracks.length < 2} direction="row" justifyItems="space-between">
            {/* <a href="https://www.flaticon.com/free-icons/camera" title="camera icons">Camera icons created by Freepik - Flaticon</a> */}
            {true && (
              <Button
                width={{ base: '40px', lg: '56px' }}
                height={{ base: '40px', lg: '56px' }}
                borderRadius={{ base: '12px', lg: '24px' }}
                p={{ base: '4px', lg: '16px' }}
                _active={{}}
                _hover={{}}
                bg="rgba(19, 37, 43, 0.50);"
                disabled={flipCameraDisabled}
                onClick={toggleFacingMode}
              >
                <FlipCamera />
              </Button>
            )}
            <Button
              width={{ base: '40px', lg: '56px' }}
              height={{ base: '40px', lg: '56px' }}
              borderRadius={{ base: '12px', lg: '24px' }}
              p={{ base: '4px', lg: '16px' }}
              _active={{}}
              _hover={{}}
              bg="rgba(19, 37, 43, 0.50);"
              onClick={toggleAudioEnabled}
            >
              <Mic isMuted={!isAudioEnabled} />
            </Button>
          </Stack>
          {roomState === 'disconnected' && <MobileLocalVideoPreview />}
          {roomState === 'connected' && <MobileUserVideo />}
        </Stack>
      </Stack>

      <Stack w="100%" justifyContent="space-between" direction="row">
        <Button
          _disabled={{
            opacity: 0.5,
          }}
          _active={{}}
          _hover={{}}
          w={{ base: '130px', lg: 'initial' }}
          h={{ base: '66px', lg: 'initial' }}
          fontFamily="Nunito"
          fontSize="24px"
          fontWeight={700}
          px={{ base: '4px', lg: '20px' }}
          py={{ base: '10px', lg: '40px' }}
          borderStyle="solid"
          borderWidth={{ base: '1px', lg: '2px' }}
          borderColor="rgba(239, 57, 17, 0.60);"
          bg="linear-gradient(247deg, rgba(239, 57, 17, 0.30) 14.88%, rgba(239, 232, 0, 0.30) 123.74%)"
          onClick={onStop}
          isDisabled={disableButtons || [VIDEOCHAT_STATES.STOP].includes(status.state)}
        >
          Stop
        </Button>
        <Button
          _active={{}}
          _hover={{}}
          _disabled={{
            opacity: 0.5,
          }}
          w={{ base: '130px', lg: 'initial' }}
          h={{ base: '66px', lg: 'initial' }}
          px={{ base: '4px', lg: '20px' }}
          py={{ base: '10px', lg: '40px' }}
          borderStyle="solid"
          borderWidth={{ base: '1px', lg: '2px' }}
          fontSize="24px"
          fontWeight={700}
          fontFamily="Nunito"
          borderColor="rgba(17, 239, 33, 0.60);"
          bg="linear-gradient(93deg, rgba(17, 239, 33, 0.30) 1.88%, rgba(239, 232, 0, 0.30) 101.06%)"
          onClick={onLetSkipBlaze}
          isDisabled={disableButtons || [VIDEOCHAT_STATES.WAITING_FOR_PARTNER].includes(status.state)}
        >
          {getMainButtonText()}
        </Button>
      </Stack>
    </Stack>
  );
}

import { Stack, Button } from '@chakra-ui/react';
import useVideoContext from '../../hooks/useVideoContext';
import Participant from '../Participant';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle';
import useFlipCameraToggle from '../../hooks/useFlipCameraToggle';
import LocalVideoPreview from '../LocalVideoPreview';
import Mic from '../../icons/MicIcon';
import FlipCamera from '../../icons/FlipCamera';

export default function UserVideo() {
  const { roomState, room, localTracks } = useVideoContext();
  const localParticipant = room?.localParticipant;

  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

  return (
    <Stack w="100%" h="100%" m={0} p={0} direction="row">
      <Stack p={0} m={0} w="100%" h="100%" position="relative" direction="row" bg="black.100">
        {roomState === 'disconnected' && <LocalVideoPreview />}
        {roomState === 'connected' && localParticipant && (
          <Participant participant={localParticipant} isLocalParticipant={true} />
        )}
        <Stack
          hidden={localTracks.length < 2}
          direction="row"
          position="absolute"
          w="100%"
          pb={{ base: '5px', lg: '10px' }}
          pl="10px"
          justifyItems="center"
          bottom={0}
        >
          {/* <a href="https://www.flaticon.com/free-icons/camera" title="camera icons">Camera icons created by Freepik - Flaticon</a> */}
          {flipCameraSupported && (
            <Button
              width={{ base: '40px', lg: '56px' }}
              height={{ base: '40px', lg: '56px' }}
              borderRadius={{ base: '12px', lg: '24px' }}
              p={{ base: '4px', lg: '16px' }}
              _active={{}}
              _hover={{}}
              bg="rgba(19, 37, 43, 0.50);"
              disabled={flipCameraDisabled}
              onClick={toggleFacingMode}
            >
              <FlipCamera />
            </Button>
          )}
          <Button
            width={{ base: '40px', lg: '56px' }}
            height={{ base: '40px', lg: '56px' }}
            borderRadius={{ base: '12px', lg: '24px' }}
            p={{ base: '4px', lg: '16px' }}
            _active={{}}
            _hover={{}}
            bg="rgba(19, 37, 43, 0.50);"
            onClick={toggleAudioEnabled}
          >
            <Mic isMuted={!isAudioEnabled} />
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

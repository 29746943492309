import React, { createContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import mixpanel from 'mixpanel-browser';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export interface IAuthProviderContext {
  app: any;
  currentUser?: any;
  login: any;
  logout: any;
  register: any;
  anonymousUser: boolean;
  forgotPassword: any;
  userExploreAnonymously: any;
}

type LoginRegisterValues = {
  email: string;
  password: string;
};

export const AuthContext = createContext<IAuthProviderContext>({
  app,
  login: (values: LoginRegisterValues) => {},
  logout: () => {},
  userExploreAnonymously: () => {},
  anonymousUser: false,
  forgotPassword: (email: string) => {},
  register: (values: LoginRegisterValues) => {},
});

export const AuthContextProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [anonymousUser, setAnonymousUser] = useState(false);

  useEffect(() => {
    getAuth().onAuthStateChanged(user => {
      const keepUserSignedIn = JSON.parse(window.localStorage.getItem('keepSignedIn') || 'false');
      if (keepUserSignedIn && user) {
        setCurrentUser(user);
      }
    });
  }, []);

  async function forgotPassword(email: string) {
    const auth = getAuth();
    return await sendPasswordResetEmail(auth, email);
  }

  async function login({ email, password }: LoginRegisterValues) {
    const auth = getAuth();
    const credentials = await signInWithEmailAndPassword(auth, email, password);
    mixpanel.identify(credentials.user.uid);
    setCurrentUser(credentials.user);
    setAnonymousUser(false);
    return credentials.user;
  }

  async function userExploreAnonymously() {
    mixpanel.identify(`anonymous_user-${Date.now()}`);
    setAnonymousUser(true);
  }

  async function logout() {
    const auth = getAuth();
    setCurrentUser(null);
    mixpanel.reset();
    return await signOut(auth);
  }

  async function register({ email, password }: LoginRegisterValues) {
    const auth = getAuth();
    const credentials = await createUserWithEmailAndPassword(auth, email, password);
    setCurrentUser(credentials.user);
  }
  return (
    <AuthContext.Provider
      value={{
        forgotPassword,
        app,
        anonymousUser,
        currentUser,
        login,
        logout,
        register,
        userExploreAnonymously,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

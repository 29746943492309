import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { CreateLocalTrackOptions, LocalAudioTrack, LocalVideoTrack, Room } from 'twilio-video';
import { ErrorCallback } from '../types';
import useHandleRoomDisconnection from '../hooks/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from '../hooks/useHandleTrackPublicationFailed';
import useLocalTracks from '../hooks/useLocalTracks';
import useRestartAudioTrackOnDeviceChange from '../hooks/useRestartAudioTrackOnDeviceChange';
import useRoom from '../hooks/useRoom';
import { useAppState } from '../hooks/useAppState';
import useConnectionOptions from '../hooks/useConnectionOptions';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room | null;
  roomState: RoomStateType;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  removeLocalAudioTrack: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  children: ReactNode;
}

type RoomStateType = 'disconnected' | 'connected' | 'reconnecting';

export function VideoProvider({ children }: VideoProviderProps) {
  const { setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const onErrorCallback: ErrorCallback = useCallback(
    error => {
      console.log(`ERROR Video provider: ${error.message}`, error);
      setError(error);
    },
    [setError]
  );

  const {
    localTracks,
    getLocalVideoTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();

  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, connectionOptions);
  const [roomState, setRoomState] = useState<RoomStateType>('disconnected');

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(room, setError, removeLocalAudioTrack, removeLocalVideoTrack);
  useHandleTrackPublicationFailed(room, setError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  // these cancel audio and video from the app after it's closed
  useEffect(() => {
    document.addEventListener('beforeunload', () => {
      removeLocalAudioTrack();
      removeLocalVideoTrack();
    });
    document.addEventListener('unload', () => {
      removeLocalAudioTrack();
      removeLocalVideoTrack();
    });
    document.addEventListener('pagehide', () => {
      removeLocalAudioTrack();
      removeLocalVideoTrack();
    });
  }, [removeLocalAudioTrack, removeLocalVideoTrack]);

  useEffect(() => {
    if (room) {
      const _setRoomState = () => setRoomState(room.state as RoomStateType);
      _setRoomState();
      room
        .on('disconnected', _setRoomState)
        .on('reconnected', _setRoomState)
        .on('reconnecting', _setRoomState);
      return () => {
        room
          .off('disconnected', _setRoomState)
          .off('reconnected', _setRoomState)
          .off('reconnecting', _setRoomState);
      };
    }
  }, [room]);

  return (
    <VideoContext.Provider
      value={{
        room,
        roomState,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        connect,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        isAcquiringLocalTracks,
        getAudioAndVideoTracks,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}

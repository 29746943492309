import { Stack, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import useVideoContext from '../../hooks/useVideoContext';
import AvatarIcon from '../../icons/AvatarIcon';
import VideoTrack from '../VideoTrack';

export default function MobileLocalVideoPreview() {
  const { localTracks, getAudioAndVideoTracks } = useVideoContext();
  const toast = useToast();

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;
  const [shouldManualFixPermissions, setShowManualFixPermissions] = useState(false);

  useEffect(() => {
    getAudioAndVideoTracks().catch(e => {
      setShowManualFixPermissions(true);
      let title = 'Allow access to camera and microphone';
      let description = 'Blazetime needs to have access to your camera and microphone.';
      if (e.message === 'CameraPermissionsDenied') {
        title = 'Allow access to camera';
        description = 'Blazetime needs to have access to your camera.';
      }
      if (e.message === 'MicrophonePermissionsDenied') {
        title = 'Allow access to microphone';
        description = 'Blazetime needs to have access to your microphone.';
      }
      toast({
        title,
        description,
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
    });
  }, []);

  return (
    <Stack
      m={0}
      p={0}
      w={localTracks.length < 2 ? '350px' : '150px'}
      h="150px"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {videoTrack && localTracks.length === 2 && <VideoTrack track={videoTrack} isLocal />}
      {localTracks.length < 2 && (
        <>
          <AvatarIcon />
          <Text
            hidden={shouldManualFixPermissions}
            w="300px"
            textAlign="center"
            color="#FFFEE9"
            fontSize={{ lg: '24px' }}
            fontWeight={400}
          >
            Blazetime needs access to your camera and microphone.
          </Text>
          <Text
            hidden={!shouldManualFixPermissions}
            w="300px"
            textAlign="center"
            color="#FFFEE9"
            fontSize={{ lg: '24px' }}
            fontWeight={400}
          >
            Blazetime needs access to your camera and microphone. Please go to settings and give access to them.
          </Text>
        </>
      )}
    </Stack>
  );
}

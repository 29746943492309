import React, { createContext, useEffect, useReducer, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from '../utils/settingsReducer';
import useActiveSinkId from '../hooks/useActiveSinkId';
import useAuthContext from '../hooks/useAuthContext';
import useAnalyitcsContext from '../hooks/useAnalyticsContext';
import { useMediaQuery } from '@chakra-ui/media-query';

export interface StateContextType {
  error: any;
  isMobile: boolean;
  setError(error: any): void;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  onlineUsers: number;
  dispatchSetting: React.Dispatch<SettingsAction>;
  updateOnlineUsers: (number: number) => void;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const { registerGAEvent, registerMixpanelEvent } = useAnalyitcsContext();
  const { currentUser } = useAuthContext();
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [isMobile] = useMediaQuery('(max-width: 431px)');

  useEffect(() => {
    const event = 'app-state-error';
    const params = {
      user: currentUser ? currentUser.sid : 'anonymous',
    };
    if (error) {
      registerGAEvent(event, params);
      registerMixpanelEvent(event, params);
    }
  }, [error]);

  function updateOnlineUsers(users: number) {
    setOnlineUsers(users);
  }

  return (
    <StateContext.Provider
      value={{
        updateOnlineUsers,
        isMobile,
        error,
        onlineUsers,
        setError,
        activeSinkId,
        setActiveSinkId,
        settings,
        dispatchSetting,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

import { Stack, Text, useToast } from '@chakra-ui/react';
import useVideoContext from '../../hooks/useVideoContext';
import Emojis, { EmojiNames } from '../../icons/EmojiIcons';
import RemoteParticipant from '../RemoteParticipant';
import { animated, useSpring } from '@react-spring/web';
import UserVideo from '../UserVideo';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import VideoChatRoomButtons from '../VideoChatRoomButtons';
import { useAppState } from '../../hooks/useAppState';
import useAuthContext from '../../hooks/useAuthContext';
import useAnalyitcsContext from '../../hooks/useAnalyticsContext';
import AttachVisibilityHandler from '../AttackVisibilityHandler';

const socketUrl = process.env.NODE_ENV === 'production' ? '/' : (process.env.REACT_APP_SOCKET_SERVER_URL as string);
let socket: any = null;

export enum VIDEOCHAT_STATES {
  WAITING_FOR_PARTNER,
  PARTNER_FOUND,
  CONNECT_TO_YOUR_ROOM,
  DISCONNECT_FROM_ROOM,
  CONNECTED,
  STOP,
}

export type APP_STATE = {
  data?: any;
  state: VIDEOCHAT_STATES;
};

export default function VideoChatRoom() {
  const { roomState, room, localTracks, connect: videoConnect } = useVideoContext();
  const toast = useToast();
  const [roomId, setRoomId] = useState('');
  const [error, setError] = useState({ title: '', description: '' });
  const { registerGAEvent, registerMixpanelEvent } = useAnalyitcsContext();
  const { currentUser } = useAuthContext();
  const { updateOnlineUsers } = useAppState();
  const [emoji, setEmoji] = useState<EmojiNames | null>(null);
  const flyOutAnimation = useSpring({
    from: {
      left: '60%',
      top: '60%',
      transform: 'scale(1)',
    },
    to: {
      left: '20%',
      top: '35%',
      transform: 'scale(2)',
    },
    config: { duration: 800 },
    reset: true,
    onRest: () => {
      setEmoji(null);
    },
  });
  const [status, setStatus] = useState<APP_STATE>({ state: VIDEOCHAT_STATES.CONNECTED });

  useEffect(() => {
    const event = 'blaze-status';
    registerGAEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
    registerMixpanelEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
    switch (status.state) {
      case VIDEOCHAT_STATES.PARTNER_FOUND:
      case VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM:
        connectTo(status.data.token);
        break;
      case VIDEOCHAT_STATES.DISCONNECT_FROM_ROOM:
        if (status.data.roomId === roomId) {
          room?.disconnect();
          setRoomId('');
          if (status.data.fromStop) {
            return setStatus({ state: VIDEOCHAT_STATES.CONNECTED });
          }
          return setStatus({ state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER });
        }
        setStatus({ state: status.data.oldState as VIDEOCHAT_STATES });
        break;
    }
  }, [status]);

  useEffect(() => {
    if (error.title) {
      const event = 'blazes-server-error';
      registerGAEvent(event, {
        user: currentUser ? currentUser.uid : 'anonymous-user',
        status: status.state,
        socketId: socket.id,
      });
      registerMixpanelEvent(event, {
        user: currentUser ? currentUser.uid : 'anonymous-user',
        status: status.state,
        socketId: socket.id,
      });
      toast({
        title: error.title,
        description: error.description,
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    socket = io(socketUrl);

    socket.on('server:onlineUsers', (onlineUsers: number) => updateOnlineUsers(onlineUsers));

    socket.on('server:error', ({ title, description }: any) => {
      setError({ title, description });
    });

    socket.on('server:partnerFound', (partnerFoundResponse: any) => {
      if (!partnerFoundResponse) {
        return setStatus({
          data: null,
          state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER,
        });
      }
      setRoomId(partnerFoundResponse.roomId);
      setStatus({
        data: partnerFoundResponse,
        state: VIDEOCHAT_STATES.PARTNER_FOUND,
      });
    });

    socket.on('server:partnerFoundDueSkip', (partnerFoundResponse: any) => {
      if (!partnerFoundResponse) {
        return setStatus({
          data: null,
          state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER,
        });
      }
      if (partnerFoundResponse.id === socket.id) {
        room?.disconnect();
        setRoomId(partnerFoundResponse.roomId);
        setStatus({
          data: partnerFoundResponse,
          state: VIDEOCHAT_STATES.PARTNER_FOUND,
        });
      }
    });

    socket.on('server:connectToYourRoom', ({ to, token, roomId }: any) => {
      if (socket.id === to) {
        setRoomId(roomId);
        setStatus({ data: { token }, state: VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM });
      }
    });

    socket.on('server:emoji', ({ to, emoji }: any) => {
      if (socket.id === to) {
        setEmoji(emoji as EmojiNames);
      }
    });

    socket.on('server:disconnectFromRoom', ({ roomId, fromStop = false }: any) => {
      setStatus({ data: { roomId, fromStop, oldState: status.state }, state: VIDEOCHAT_STATES.DISCONNECT_FROM_ROOM });
    });

    return () => {
      room?.disconnect();
      socket.disconnect();
    };
  }, []);

  function reactWithEmoji(emoji: EmojiNames) {
    registerGAEvent(`blaze-emoji-${emoji.toString()}-choose`, {
      user: currentUser ? currentUser.uid : 'anonymous-user',
      status: status.state,
    });
    registerMixpanelEvent(`blaze-emoji-${emoji.toString()}-choose`, {
      user: currentUser ? currentUser.uid : 'anonymous-user',
      status: status.state,
    });
    socket.emit('client:emoji', emoji);
    setEmoji(emoji);
  }

  function onLetSkipBlaze() {
    if (status.state === VIDEOCHAT_STATES.PARTNER_FOUND || status.state === VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM) {
      registerGAEvent('blaze-skip', { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
      registerMixpanelEvent('blaze-skip', {
        user: currentUser ? currentUser.uid : 'anonymous-user',
        status: status.state,
      });
      setStatus({ state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER });
      return socket.emit('client:skip');
    }
    registerGAEvent('blaze-start', { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
    registerMixpanelEvent('blaze-start', {
      user: currentUser ? currentUser.uid : 'anonymous-user',
      status: status.state,
    });
    setStatus({ state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER });
    socket.emit('client:findPartner');
  }

  function onStop() {
    registerGAEvent('blaze-stop', { user: currentUser ? currentUser.uid : 'anonymous-user', status: status.state });
    registerMixpanelEvent('blaze-stop', {
      user: currentUser ? currentUser.uid : 'anonymous-user',
      status: status.state,
    });
    setStatus({ state: VIDEOCHAT_STATES.STOP });
    socket.emit('client:stop');
  }

  async function connectTo(token: string) {
    try {
      await videoConnect(token);
      const event = 'videochat-started';
      registerGAEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user' });
      registerMixpanelEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user' });
    } catch (e) {
      const event = 'videochat-started-failed';
      registerGAEvent(event, {
        // @ts-ignore
        error: e.message,
      });
      registerMixpanelEvent(event, {
        // @ts-ignore
        error: e.message,
      });
      toast({
        title: 'There was an error',
        // @ts-ignore
        description: `Can't connect with your Blazetime partner.`,
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
      setStatus({ state: VIDEOCHAT_STATES.WAITING_FOR_PARTNER });
    }
  }

  function getMainButtonText() {
    switch (status.state) {
      case VIDEOCHAT_STATES.CONNECTED:
      case VIDEOCHAT_STATES.STOP:
        return 'Blaze!';
      case VIDEOCHAT_STATES.WAITING_FOR_PARTNER:
      case VIDEOCHAT_STATES.DISCONNECT_FROM_ROOM:
        return 'Waiting...';
      case VIDEOCHAT_STATES.PARTNER_FOUND:
      case VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM:
        return 'Skip';
    }
  }

  function getRemoteParticipantText() {
    switch (status.state) {
      case VIDEOCHAT_STATES.CONNECTED:
      case VIDEOCHAT_STATES.STOP:
        return `Cannabis comes in various strains, each with its own unique combination of cannabinoids and terpenes.
          Indica strains are often associated with relaxation, while sativa strains may be more energizing.`;
      case VIDEOCHAT_STATES.WAITING_FOR_PARTNER:
      case VIDEOCHAT_STATES.DISCONNECT_FROM_ROOM:
        return 'Waiting for a smoke buddy';
      case VIDEOCHAT_STATES.PARTNER_FOUND:
      case VIDEOCHAT_STATES.CONNECT_TO_YOUR_ROOM:
        return 'Connecting to your smoke buddy...';
    }
  }

  function renderRandomWeed() {
    const leftWeeds = [
      {
        left: '10px',
        top: '160px',
        transform: `scale(0.75)`,
      },
      {
        left: '180px',
        top: '160px',
      },
      {
        left: '120px',
        top: '330px',
        transform: `scale(1.5)`,
      },
      {
        left: '10px',
        top: '60vh',
        transform: `scale(1)`,
      },
      {
        left: '180px',
        top: '65vh',
        transform: `scale(0.5)`,
      },
      {
        left: '80px',
        top: '80vh',
        transform: `scale(1.5)`,
      },
    ];
    const rightWeeds = [
      {
        right: '10px',
        top: '160px',
        transform: `scale(0.75)`,
      },
      {
        right: '180px',
        top: '160px',
      },
      {
        right: '120px',
        top: '330px',
        transform: `scale(1.5)`,
      },
      {
        right: '10px',
        top: '60vh',
        transform: `scale(1)`,
      },
      {
        right: '180px',
        top: '65vh',
        transform: `scale(0.5)`,
      },
      {
        right: '80px',
        top: '80vh',
        transform: `scale(1.5)`,
      },
    ];
    return [...leftWeeds, ...rightWeeds].map(props => (
      <div style={{ ...props, position: 'absolute' }}>
        <Emojis iconName={EmojiNames.WEED} />
      </div>
    ));
  }

  return (
    <Stack
      id="elements-container"
      w="100%"
      py={{ base: '10px', lg: '40px' }}
      px={{ base: '16px', lg: '120px' }}
      spacing={{ lg: '16px' }}
      h="100%"
      justifyContent={{ base: 'space-evenly', lg: 'center' }}
      alignItems="center"
      direction="column"
    >
      {renderRandomWeed()}
      <AttachVisibilityHandler />
      {emoji && (
        <animated.div style={{ zIndex: 9999, position: 'absolute', ...flyOutAnimation }}>
          <Emojis iconName={emoji} />
        </animated.div>
      )}
      <Stack
        w={{ base: '90%', lg: '60%' }}
        h={{ base: '40%', lg: '55%' }}
        maxW={{ base: '90%', lg: '60%' }}
        maxH={{ base: '50%', lg: '55%' }}
        alignItems="center"
        justifyContent="center"
      >
        {roomState !== 'disconnected' && <RemoteParticipant reactWithEmoji={reactWithEmoji} />}
        <Stack
          w="100%"
          h="100%"
          hidden={roomState !== 'disconnected'}
          alignItems="center"
          justifyContent="center"
          bg="rgba(255, 254, 233, 0.50)"
          borderRadius={{ base: '16px', lg: '32px' }}
          p={{ base: '32px', lg: '40px' }}
        >
          <Text textAlign="center" color="#FFFEE9" fontSize={{ base: '14px', lg: '24px' }} fontWeight={400}>
            {getRemoteParticipantText()}
          </Text>
        </Stack>
      </Stack>
      <Stack
        m={0}
        p={0}
        direction={{ base: 'column', lg: 'row' }}
        w={{ base: '90%', lg: '55%' }}
        h={{ base: '40%', lg: '40%' }}
        maxW={{ base: '90%', lg: '55%' }}
        maxH={{ base: '30%', lg: '40%' }}
        alignItems="center"
        justifyContent="center"
      >
        <UserVideo />
        {localTracks.length === 2 && (
          <VideoChatRoomButtons
            state={status.state}
            mainButtonText={getMainButtonText()}
            onMainButtonClick={onLetSkipBlaze}
            onStopButtonClick={onStop}
          />
        )}
      </Stack>
    </Stack>
  );
}

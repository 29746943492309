import VideoChatRoom from '../../components/VideoChatRoom';
import MobileVideoChatRoom from '../../components/VideoChatRoom/mobile';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useAuthContext from '../../hooks/useAuthContext';
import useAnalyitcsContext from '../../hooks/useAnalyticsContext';
import { useAppState } from '../../hooks/useAppState';

export default function LetsBlaze() {
  const { currentUser, anonymousUser } = useAuthContext();
  const { isMobile } = useAppState();
  const { registerGAEvent, registerMixpanelEvent } = useAnalyitcsContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser || !anonymousUser) {
      const event = 'user-visits-blaze';
      registerGAEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user' });
      registerMixpanelEvent(event, { user: currentUser ? currentUser.uid : 'anonymous-user' });
    }
  }, []);

  useEffect(() => {
    if (!currentUser && !anonymousUser) {
      return navigate('/');
    }
  }, [currentUser, navigate, anonymousUser]);

  if (!currentUser && !anonymousUser) {
    return null;
  }

  if (isMobile) {
    return <MobileVideoChatRoom />;
  }
  return <VideoChatRoom />;
}

import { Stack } from '@chakra-ui/react';
import useVideoContext from '../../hooks/useVideoContext';
import Participant from '../Participant';

export default function MobileUserVideo() {
  const { roomState, room } = useVideoContext();
  const localParticipant = room?.localParticipant;

  return (
    <Stack m={0} p={0} w="150px" h="150px" direction="row">
      {roomState === 'connected' && localParticipant && (
        <Participant participant={localParticipant} isLocalParticipant={true} />
      )}
    </Stack>
  );
}

import { useContext } from 'react';
import { AnalyticsContext } from '../providers/AnalyticsProvider';

export default function useAnalyitcsContext() {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalyitcsContext must be used within a AnalyticsProvider');
  }
  return context;
}

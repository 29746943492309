export default function FlipCamera() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
        <path
          d="M2015 4705 c-141 -26 -261 -82 -368 -170 -85 -70 -150 -157 -222
        -295 -84 -160 -69 -154 -350 -161 -235 -7 -301 -15 -415 -51 -334 -107 -585
        -397 -645 -748 -14 -75 -15 -229 -12 -1081 3 -1088 0 -1024 62 -1189 88 -231
        306 -451 536 -540 180 -69 36 -64 1911 -67 1867 -4 1807 -5 1979 57 322 116
        553 394 614 738 22 119 21 1963 0 2085 -41 238 -175 457 -369 604 -77 58 -239
        136 -330 158 -97 24 -224 34 -411 35 -213 0 -216 2 -302 164 -110 206 -210
        310 -378 391 -166 80 -155 79 -725 81 -388 2 -521 0 -575 -11z m1041 -296
        c163 -29 278 -126 378 -318 90 -171 173 -250 307 -292 50 -16 92 -19 238 -19
        207 0 318 -12 407 -44 191 -69 340 -225 406 -421 l23 -70 3 -970 c3 -1091 5
        -1061 -69 -1211 -76 -155 -215 -274 -385 -330 l-89 -29 -1715 0 -1715 0 -89
        29 c-170 56 -310 176 -385 330 -74 152 -72 112 -69 1206 l3 975 27 81 c65 190
        213 341 402 410 89 32 200 44 407 44 146 0 188 3 238 19 134 42 217 121 307
        292 76 146 148 226 248 274 39 18 94 38 121 43 71 14 927 15 1001 1z"
        />
        <path
          d="M2339 3575 c-369 -70 -685 -290 -876 -611 -90 -150 -157 -355 -170
        -520 l-6 -83 -54 49 c-34 31 -67 52 -89 56 -105 20 -197 -78 -174 -182 7 -30
        43 -72 192 -221 101 -102 195 -191 210 -199 32 -17 91 -18 121 -3 12 6 106 95
        210 198 147 146 190 194 198 224 28 104 -72 206 -180 182 -17 -3 -54 -27 -82
        -53 l-52 -47 5 60 c20 213 123 423 287 585 187 186 416 280 681 280 60 0 141
        -6 182 -14 168 -33 341 -118 463 -229 75 -68 96 -79 150 -79 88 0 145 57 145
        146 0 64 -21 96 -113 174 -157 131 -322 216 -518 267 -90 24 -128 28 -279 31
        -123 2 -197 -1 -251 -11z"
        />
        <path
          d="M3630 2779 c-14 -5 -109 -94 -213 -197 -204 -203 -218 -225 -198
        -300 17 -60 81 -112 140 -112 46 0 82 18 129 63 l42 41 0 -39 c-1 -62 -36
        -206 -71 -291 -67 -161 -216 -341 -362 -437 -230 -153 -526 -200 -797 -127
        -104 29 -263 110 -340 175 -127 107 -129 108 -179 113 -94 9 -161 -50 -161
        -142 0 -68 20 -97 124 -184 348 -288 802 -371 1234 -226 360 121 668 433 792
        804 28 82 60 247 60 305 0 25 4 45 8 45 4 0 24 -16 43 -36 76 -81 168 -85 237
        -11 13 14 27 41 32 62 17 73 1 97 -193 294 -100 101 -196 189 -214 197 -37 16
        -77 17 -113 3z"
        />
      </g>
    </svg>
  );
}

export default function UserIcon() {
  return (
    <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 30">
        <rect width="50" height="50" rx="25" fill="#13252B" />
        <g id="Group">
          <path
            id="Vector"
            d="M25.0003 10.1666C27.0605 10.1666 29.0745 10.7776 30.7875 11.9222C32.5005 13.0667 33.8357 14.6936 34.6241 16.597C35.4125 18.5004 35.6188 20.5948 35.2168 22.6155C34.8149 24.6361 33.8228 26.4922 32.366 27.949C30.9092 29.4058 29.0532 30.3979 27.0325 30.7998C25.0119 31.2017 22.9174 30.9955 21.014 30.207C19.1106 29.4186 17.4838 28.0835 16.3392 26.3705C15.1946 24.6575 14.5837 22.6435 14.5837 20.5833L14.5941 20.1312C14.7106 17.4494 15.8578 14.9162 17.7966 13.0597C19.7354 11.2032 22.316 10.1668 25.0003 10.1666ZM29.167 35.1666C31.9297 35.1666 34.5792 36.2641 36.5327 38.2176C38.4862 40.1711 39.5837 42.8206 39.5837 45.5833V47.6666C39.5837 48.7717 39.1447 49.8315 38.3633 50.6129C37.5819 51.3943 36.5221 51.8333 35.417 51.8333H14.5837C13.4786 51.8333 12.4188 51.3943 11.6374 50.6129C10.856 49.8315 10.417 48.7717 10.417 47.6666V45.5833C10.417 42.8206 11.5145 40.1711 13.468 38.2176C15.4215 36.2641 18.071 35.1666 20.8337 35.1666H29.167Z"
            fill="#FFFEE9"
          />
        </g>
      </g>
    </svg>
  );
}

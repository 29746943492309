import { useAppState } from '../../hooks/useAppState';
import { Text } from '@chakra-ui/react';
import { useLocation } from 'react-router';

export default function OnlinePeople() {
  const { onlineUsers } = useAppState();
  const { pathname } = useLocation();

  if (!onlineUsers) {
    return null;
  }

  return (
    <Text
      hidden={pathname === '/'}
      letterSpacing="-0.52px"
      color="transparent"
      backgroundClip="text"
      css={{ '-webkit-background-clip': 'text', 'background-clip': 'text' }}
      bg="linear-gradient(94deg, #13252B 11.85%, #11EF21 51.98%, #EFE800 92.11%)"
      fontSize={{ base: '14px', lg: '20px' }}
      fontWeight={900}
      fontFamily="Nunito"
    >
      {onlineUsers} online {onlineUsers === 1 ? 'user' : 'users'}
    </Text>
  );
}

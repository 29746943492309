import { Heading, Stack, Text, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignInUpForm, { FormFields } from '../SignInUpForm';
import { useToast } from '@chakra-ui/react';
import AcceptanceCheckbox from '../AcceptanceCheckbox';
import useAuthContext from '../../hooks/useAuthContext';
import { SIGNINUP_ERRORS } from '../../constants';
import useAnalyitcsContext from '../../hooks/useAnalyticsContext';

type SignInUpProps = {
  showForgotPassword: boolean;
  setShowForgotPassword: (value: boolean) => void;
  setAcceptanceValue: (value: boolean) => void;
  acceptanceValue: boolean;
  isSignUp: boolean;
  exploreAnonymously: () => void;
  setIsSignUp: (value: boolean) => void;
};

export default function SignInUp({
  setAcceptanceValue,
  showForgotPassword,
  acceptanceValue,
  setShowForgotPassword,
  setIsSignUp,
  isSignUp,
  exploreAnonymously,
}: SignInUpProps) {
  const navigate = useNavigate();
  const { login, register, forgotPassword } = useAuthContext();
  const { registerGAEvent, registerMixpanelEvent } = useAnalyitcsContext();
  const title = showForgotPassword ? 'Forgot password?' : isSignUp ? 'Create an account' : 'Sign in';
  const toast = useToast();

  async function onCreateAccount(values: FormFields) {
    toast.closeAll();
    if (!acceptanceValue) {
      return toast({
        title: 'Confirm you age at least 18 years old',
        description: 'To create an account you must accept our age restriction.',
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
    }
    try {
      await register(values);
      const event = 'sign-up-success';
      const params = {
        email: values.email,
      };
      registerGAEvent(event, params);
      registerMixpanelEvent(event, params);

      await login(values);

      navigate('/blaze');
    } catch (e) {
      const event = 'create-account-failed';
      const params = {
        //@ts-ignore
        errorCode: e.code,
        //@ts-ignore
        error: e.message,
      };
      registerGAEvent(event, params);
      registerMixpanelEvent(event, params);
      // @ts-ignore
      const { title, description } = SIGNINUP_ERRORS[e.code] || SIGNINUP_ERRORS.DEFAULT_ERROR;
      toast({
        title,
        description,
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
      setIsSignUp(true);
    }
  }

  async function onSignIn(values: FormFields) {
    toast.closeAll();
    try {
      const user = await login(values);
      const event = 'sign-in-success';
      const params = {
        //@ts-ignore
        user: user.uid,
      };
      registerGAEvent(event, params);
      registerMixpanelEvent(event, params);
      setIsSignUp(false);
      navigate('/blaze');
    } catch (e) {
      const event = 'sign-in-failed';
      const params = {
        //@ts-ignore
        errorCode: e.code,
        //@ts-ignore
        error: e.message,
      };
      registerGAEvent(event, params);
      registerMixpanelEvent(event, params);
      // @ts-ignore
      const { title, description } = SIGNINUP_ERRORS[e.code] || SIGNINUP_ERRORS.DEFAULT_ERROR;
      toast({
        title,
        description,
        status: 'error',
        duration: 1000 * 3,
        isClosable: true,
      });
      setIsSignUp(false);
    }
  }

  async function onForgotPassword(email: string) {
    try {
      await forgotPassword(email);
      const event = 'forgot-password';
      const params = {
        email,
      };
      registerGAEvent(event, params);
      registerMixpanelEvent(event, params);
      setShowForgotPassword(false);
      setIsSignUp(false);
      toast({
        title: 'Password reset sent',
        description: 'We sent you an email to reset your password.',
        status: 'success',
        duration: 1000 * 5,
        isClosable: true,
      });
    } catch (e) {
      const event = 'forgot-password-failed';
      const params = {
        //@ts-ignore
        errorCode: e.code,
        //@ts-ignore
        error: e.message,
      };
      registerGAEvent(event, params);
      registerMixpanelEvent(event, params);
      toast({
        title: 'There was an error',
        description: 'Please contact support team.',
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
    }
  }

  function onKeepMeSignedInChange(value: boolean) {
    window.localStorage.setItem('keepSignedIn', JSON.stringify(value));
  }

  return (
    <Stack px={{ base: '48px', lg: '25%' }} direction="column" spacing={{ base: '15px', lg: '30px' }} color="light.100">
      <Heading fontSize={{ base: '16px', lg: '28px' }} fontWeight={900}>
        {title}
      </Heading>
      <SignInUpForm
        showForgotPassword={showForgotPassword}
        onForgotPassword={() => setShowForgotPassword(true)}
        onForgotPasswordClaimed={onForgotPassword}
        onKeepMeSignedInChange={onKeepMeSignedInChange}
        isSignUp={isSignUp}
        onCreateAccount={onCreateAccount}
        onSignIn={onSignIn}
      />
      <Button
        _hover={{}}
        _active={{}}
        bg="#E2E8F0"
        borderRadius="12px"
        onClick={exploreAnonymously}
        fontWeight={700}
        color="black"
        fontSize={{ base: '14px', lg: '20px' }}
        border="none"
        px={{ lg: '20px' }}
        py={{ lg: '16px' }}
      >
        Explore Anonymously
      </Button>
      <AcceptanceCheckbox onChange={setAcceptanceValue} />
      <Stack justifyContent="center" direction="row" alignItems="center">
        <Text fontFamily="Lato" fontSize="16px" fontWeight={900}>
          {isSignUp || showForgotPassword ? 'Already have an account?' : 'New here? Create an account.'}
        </Text>
        <Button
          _active={{}}
          _hover={{}}
          cursor="pointer"
          textDecoration="underline"
          fontFamily="Lato"
          fontSize="16px"
          fontWeight={900}
          border=""
          color="light.100"
          variant="outline"
          p={0}
          m={0}
          onClick={() => {
            setIsSignUp(!isSignUp);
            setShowForgotPassword(false);
          }}
        >
          {isSignUp || showForgotPassword ? 'Sign in' : 'Sign up'}
        </Button>
      </Stack>
    </Stack>
  );
}

import { IconButton, Menu, MenuButton, MenuList, MenuItem, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import useAuthContext from '../../hooks/useAuthContext';
import UserIcon from '../../icons/UserIcon';
import SignInButton from '../SignInButton';

export default function UserMenu() {
  const { logout, currentUser } = useAuthContext();
  const navigate = useNavigate();
  const toast = useToast();

  if (!currentUser) {
    return <SignInButton />;
  }

  async function signOut() {
    try {
      window.localStorage.setItem('keepSignedIn', JSON.stringify(false));
      await logout();
      navigate('/');
    } catch (e) {
      toast({
        title: 'There was an error',
        description: 'Cannot sign out, please reach suppor team.',
        status: 'error',
        duration: 1000 * 3,
        isClosable: true,
      });
    }
  }

  return (
    <Menu>
      <MenuButton
        w={{ lg: '40px' }}
        h={{ lg: '40px' }}
        _active={{}}
        _hover={{}}
        bg="transparent"
        as={IconButton}
        icon={<UserIcon />}
      ></MenuButton>
      <MenuList>
        <MenuItem onClick={signOut}>Sign out</MenuItem>
      </MenuList>
    </Menu>
  );
}

export default function EmojiButtonIcon() {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 0.879883C8.046 0.879883 0 8.97988 0 18.8799C0 23.6538 1.89642 28.2321 5.27208 31.6078C6.94353 33.2793 8.92784 34.6051 11.1117 35.5097C13.2956 36.4143 15.6362 36.8799 18 36.8799C22.7739 36.8799 27.3523 34.9835 30.7279 31.6078C34.1036 28.2321 36 23.6538 36 18.8799C36 16.5161 35.5344 14.1754 34.6298 11.9916C33.7252 9.80772 32.3994 7.82342 30.7279 6.15196C29.0565 4.48051 27.0722 3.15464 24.8883 2.25005C22.7044 1.34547 20.3638 0.879883 18 0.879883ZM24.3 11.6799C25.0161 11.6799 25.7028 11.9643 26.2092 12.4707C26.7155 12.977 27 13.6638 27 14.3799C27 15.096 26.7155 15.7827 26.2092 16.2891C25.7028 16.7954 25.0161 17.0799 24.3 17.0799C23.5839 17.0799 22.8972 16.7954 22.3908 16.2891C21.8845 15.7827 21.6 15.096 21.6 14.3799C21.6 13.6638 21.8845 12.977 22.3908 12.4707C22.8972 11.9643 23.5839 11.6799 24.3 11.6799ZM11.7 11.6799C12.4161 11.6799 13.1028 11.9643 13.6092 12.4707C14.1155 12.977 14.4 13.6638 14.4 14.3799C14.4 15.096 14.1155 15.7827 13.6092 16.2891C13.1028 16.7954 12.4161 17.0799 11.7 17.0799C10.9839 17.0799 10.2972 16.7954 9.79081 16.2891C9.28446 15.7827 9 15.096 9 14.3799C9 13.6638 9.28446 12.977 9.79081 12.4707C10.2972 11.9643 10.9839 11.6799 11.7 11.6799ZM18 28.7799C13.806 28.7799 10.242 26.1519 8.802 22.4799H27.198C25.74 26.1519 22.194 28.7799 18 28.7799Z"
        fill="#13252B"
        fillOpacity="0.5"
      />
    </svg>
  );
}

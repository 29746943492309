import { Stack, Text, useMediaQuery, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import HomePageVideo from '../../components/HomePageVideo';
import SignInUp from '../../components/SignInUp';
import useAnalyitcsContext from '../../hooks/useAnalyticsContext';
import useAuthContext from '../../hooks/useAuthContext';

export default function HomePage() {
  const { currentUser } = useAuthContext();
  const { userExploreAnonymously } = useAuthContext();
  const { registerGAEvent, registerMixpanelEvent } = useAnalyitcsContext();
  const [acceptanceValue, setAcceptanceValue] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [isMobile] = useMediaQuery('(max-width: 426px)');

  useEffect(() => {
    const event = 'user-visits-home';
    registerGAEvent(event);
    registerMixpanelEvent(event);
  }, []);

  useEffect(() => {
    if (currentUser) {
      return navigate('/blaze');
    }
  }, [currentUser, navigate]);

  function exploreAnonymously() {
    if (!acceptanceValue) {
      return toast({
        // @ts-ignore
        title: 'Confirm you age at least 18 years old',
        // @ts-ignore
        description: 'To create an account you must accept our age restriction.',
        status: 'error',
        duration: 1000 * 5,
        isClosable: true,
      });
    }
    const event = 'explore-anonymously';
    registerGAEvent(event);
    registerMixpanelEvent(event);
    userExploreAnonymously();
    navigate('/blaze');
  }

  return (
    <Stack spacing={0} h="100%" direction="row">
      <Stack w="50vw" h="100%" display={{ base: 'none', lg: 'flex' }}>
        <Text
          letterSpacing="-0.52px"
          position="absolute"
          width={{ md: '45vw', lg: '40vw' }}
          color="transparent"
          backgroundClip="text"
          css={{ '-webkit-background-clip': 'text', 'background-clip': 'text' }}
          bg="linear-gradient(94deg, #13252B 11.85%, #11EF21 51.98%, #EFE800 92.11%)"
          fontSize={{ md: '40px', lg: '52px' }}
          top={{ md: '25vh', lg: '30vh' }}
          left={{ md: '2vw', lg: '5vw' }}
          fontWeight={900}
          lineHeight="120%"
          fontFamily="Nunito"
        >
          Welcome to the BlazeTime Community
        </Text>
        <HomePageVideo />
      </Stack>
      <Stack
        bg={{ base: 'none', md: 'black.100' }}
        h="100%"
        direction="column"
        alignItems="center"
        justifyContent="space-around"
        w={{ base: '100vw', md: '50vw' }}
      >
        {isMobile && <HomePageVideo />}
        <SignInUp
          exploreAnonymously={exploreAnonymously}
          isSignUp={isSignUp}
          setIsSignUp={setIsSignUp}
          showForgotPassword={showForgotPassword}
          acceptanceValue={acceptanceValue}
          setAcceptanceValue={setAcceptanceValue}
          setShowForgotPassword={setShowForgotPassword}
        />
      </Stack>
    </Stack>
  );
}

import React from 'react';
import ParticipantTracks from '../ParticipantTracks';
import { Participant as IParticipant } from 'twilio-video';

interface ParticipantProps {
  participant: IParticipant;
  videoOnly?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

function Participant({ participant, videoOnly, isLocalParticipant }: ParticipantProps) {
  return <ParticipantTracks participant={participant} videoOnly={videoOnly} isLocalParticipant={isLocalParticipant} />;
}

export default React.memo(Participant);
